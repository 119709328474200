import React, { useState, useEffect } from "react";
import Head_CateHead from "../components/Head_CateHead.js";
import { Slide_title } from "./../components/slide-title.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Footer } from "../components/Footer.js";
import { terms_condition } from "../service/api.js";

const AccordionItem = ({ expanded, onChange, id, header, content, canClose }) => (
    <Accordion expanded={true} onChange={onChange} disabled={!canClose && !expanded}>
      <AccordionSummary
        expandIcon={expanded ? "-" : "+"}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        className="faqpage"
      >
        <Typography>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails className="faqpage1">
        <Typography dangerouslySetInnerHTML={{ __html: content }}>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
  


export const Terms_condition = () => {
  const [expanded, setExpanded] = useState("");
  const [updatedAccordionData1, setupdatedAccordionData1] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };
  const getdata = async () => {
    try {
      const res = await terms_condition();
      const data = res.data.data.Description;
console.log(data);
      const updatedAccordionData = data.map((item, index) => {
        console.log(item.Title)
        const i = index + 1;
        const header = item.Title; // Replace underscores with spaces
        const content = item.Description; 
          return {
            id: `pane${i}a`,
            header: header,
            content: content,
          };
      });

      // Now updatedAccordionData has the desired structure
      console.log(updatedAccordionData);
      setupdatedAccordionData1(updatedAccordionData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




  useEffect(() => {
    getdata();
  }, []);

 
  const accordionData = [
    // {
    //   id: "pane1a",
    //   header: "Orders",
    //   content:
    //     "Please confirm all orders by fax, post or email with your purchase order number or reference for billing. <br/>We do not accept any verbal purchase orders and instructions.",
    //     canClose: false,
    // },
    // {
    //     id: "pane2a",
    //     header: "Trading Terms",
    //     content:
    //       "We are a family owned and operated promotional merchandise company who love what we do. We love seeing our client get results, we wont just sell you a product because we make more, we provide you products suited to your business and campaigns.",
    //   },
    //   {
    //     id: "pane3a",
    //     header: "Ownership of Goods",
    //     content:
    //       "All goods remain the property of BeBranded until they have been paid for in full.",
    //   },
    //   {
    //     id: "pane4a",
    //     header: "Discrepancies",
    //     content:
    //       "2% over or under delivery considered fulfillment on all orders. <br/>Request exact quantities may incur a surcharge.",
    //   },
    //   {
    //     id: "pane5a",
    //     header: " SReservingtock",
    //     content:
    //       "Stock can only be put on hold for maximum 24 hours. Please send written confirmation.",
    //   },
    //   {
    //     id: "pane6a",
    //     header: "Returns",
    //     content:
    //       "Goods returned for credit will be accepted only if prior approval has been provided with authorization number. Products returned must be in re-saleable condition and return within 14 days of the delivery",
    //   },
    //   {
    //     id: "pane7a",
    //     header: "Delivery to The Third Party",
    //     content:
    //       "If BeBranded delivers to a third party, then it is the responsibility of the purchaser to ensure that the products ordered are correct. We accept no responsibility for goods lost by third party. Additionally, if we deliver incorrect unprinted product to you or direct to your printer and the incorrect goods are printed under your direction, then under no circumstances will we accept responsibility for the goods or accept a return for credit.",
    //   },
    //   {
    //     id: "pane8a",
    //     header: "Complaints",
    //     content:
    //       "All complaints are to be received in writing within 7 days of delivery.",
    //   },
    //   {
    //     id: "pane9a",
    //     header: "Methods of Payment",
    //     content:
    //       "We accept VISA/Master Card. Credit payment will NOT attract any surcharge.",
    //   },
    //   {
    //     id: "pane10a",
    //     header: "Internet Banking",
    //     content:
    //       "ANZ Bank<br/>Account Name: Be Branded<br/>BSB: 014 316<br/>Account Number: 289 160 135",
    //   },
    //   {
    //     id: "pane11a",
    //     header: "Samples",
    //     content:
    //       "FREE samples are available on MOST products, some samples incur a discounted surcharge.<br/>Please note ALL paid samples will be credited against an order of that product.",
    //   },
    //   {
    //     id: "pane12a",
    //     header: "Freight",
    //     content:
    //       "ALL Freight costs are included in pricing.<br/>BeBranded offer dispatch dates as turnaround times. Once good leave our factory BeBranded cannot be responsible for delays.<br/>However, we will do everything we can to assist should this unfortunate issue arise.<br/>If the first delivery attempt during normal business hours is unsuccessful, BeBranded will pass on the re-delivery charges from the courier to the customer.<br/>Our customers need to get authorization from BeBranded for any re-direction ,BeBranded will passon the re-direction charges from the courier to the customer.<br/>Split Delivery – Please contact us regarding split order deliveries.",
    //   },
    //   {
    //     id: "pane13a",
    //     header: "Artwork Requirement",
    //     content:
    //       "Artwork is to be emailed to: Orders@bebranded.com.au<br/>For best quality, please create your file in Corel Draw, Adobe Illustrator and Photoshop and save in the following formats:<br/>EPS format<br/>CDR format<br/>PDF format<br/>Please convert your fonts to curves in CorelDraw and outlines in Adobe Illustrator.",
    //   },
    // Add more items as needed
  ];
  return (
    <>
      <Head_CateHead />
      <div className="container faq_wrapper terms_wrapper">
        <Slide_title title="Terms & Condition" className="faq_title" />
        <hr />
        <Slide_title title="Please add 10% GST to all prices quoted" className="terms_title" />
        {updatedAccordionData1.map((item, index) => (
          <AccordionItem
            key={item.id}
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
            {...item}
          />
        ))}
      </div>
      <Footer />
    </>
  );
};
