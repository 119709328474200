import React from 'react';
import { NavLink } from 'react-router-dom';
import { Slide_title } from "../components/slide-title.js";
import { Category_Header } from "../components/category-header.js";
import Head_CateHead from './Head_CateHead.js';

export const Account_head = ({ title_ }) => {
  return (
    <div>
      <div className="container account">
        <Slide_title title="Account" className="account_page_title" />
        <p className='account_subtitle'>{localStorage.getItem("FullName")}, <span>{localStorage.getItem("Email")}</span></p>
        <hr />
        <ul className='account_menu'>
          <NavLink to="/account" activeClassName="active"><li><button>Account info</button></li></NavLink>
          <NavLink to="/wishlist" activeClassName="active"><li><button>Wishlist</button></li></NavLink>
          <NavLink to="/order_list" activeClassName="active"><li><button>Order List</button></li></NavLink>
        <NavLink to="/change_password" activeClassName="active"><li><button>Change password</button></li></NavLink>
      <NavLink to="/saved_address" activeClassName="active"><li><button>Saved Address</button></li></NavLink>
        </ul>
        <hr className='hr_set' />
        <Slide_title title={title_} className="account_detail_title" />
      </div>
    </div>
  );
}
