import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "../components/slide-title.js";
import { Account_head } from "../components/account_head.js";
import { Remove_btn } from "../components/remove_button.js";
import { add_Baddress } from "../service/api.js";
import { billing_address_detail } from "../service/api.js";
import { Update_Baddress } from "../service/api.js";
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import Head_CateHead from '../components/Head_CateHead.js';

export const Acc_Baddress = () => {
  const navigate = useNavigate();
    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const [submitbutton, setsubmitbutton] = useState(true);
    const {id} = useParams()
    function handleExceptionError(res) {
      // alert("sf");
      setExceptionError(ExceptionError => [
        ...ExceptionError,
        { id: Date.now(), message: res },
      ]);
    }
  
    function handleExceptionSuccessMessages(res) {
      setSuccessMessages(successMessages => [
        ...successMessages,
        { id: Date.now(), message: res.data.Message },
      ]);
    }
  
    function clearErrors(id) {
      setExceptionError(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
    function clearSuccess(id) {
      setSuccessMessages(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
    const [data, setData] = useState({
        BCustomerName: '',
        BCountry: '',
        BState: '',
        BCity: '',
        BAddress1: '',
        BAddress2: '',
        BEmail: '',
        BMobileNumber: '',
        BZipcode:'',
      });
    
      const [validationErrors, setValidationErrors] = useState({
        BCustomerName: '',
        BCountry: '',
        BState: '',
        BCity: '',
        BAddress1: '',
        BAddress2: '',
        BEmail: '',
        BMobileNumber: '',
        BZipcode:'',
      });
      
      const data_get = async () => {
        try {
          const res = await billing_address_detail(id);
          setData({
            BCustomerName: res.data.Data.BCustomerName,
            BCountry: res.data.Data.BCountry,
            BState: res.data.Data.BState,
            BCity: res.data.Data.BCity,
            BAddress1: res.data.Data.BAddress1,
            BAddress2: res.data.Data.BAddress2,
            BZipcode: res.data.Data.BZipcode,
            BEmail: res.data.Data.BEmail,
            BMobileNumber: res.data.Data.BMobileNumber
          })
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      useEffect(() => {
        if(!localStorage.getItem('token')){
          navigate("/signin");   
        }
        if (id){
          data_get();
        }
      }, []);
      const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (data.BCustomerName.trim() === '') {
          errors.BCustomerName = 'Fullname is required.';
        }
        if (data.BCountry.trim() === '') {
          errors.BCountry = 'Country is required.';
        }
        if (data.BState.trim() === '') {
          errors.BState = 'State is required.';
        }
        if (data.BCity.trim() === '') {
          errors.BCity = 'City is required.';
        }
        if (data.BAddress1.trim() === '') {
          errors.BAddress1 = 'Address Line 1 is required.';
        }
        if (data.BEmail.trim() === '') {
          errors.BEmail = 'Email is required.';
        }
        if (data.BEmail){
          const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
          if (!email_pattern.test(data.BEmail)) {
            errors.BEmail = "Invalid Email format";
        }
        }
        if (data.BMobileNumber.trim() === '') {                                                   
          errors.BMobileNumber = 'MobileNumber is required.';
        }
        if (!data.BZipcode) {
          errors.BZipcode = 'Zipcode is required.';
        }
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
        setValidationErrors({});
        setsubmitbutton(false);
        if (id) {
          const res = await Update_Baddress(data,id);
          if (res.status == 200) {
              handleExceptionSuccessMessages(res);
              navigate("/saved_address");   
            }else{
              setsubmitbutton(true);
                handleExceptionError(res.response.data.ErrorMessage)
            }
          }        
        else {
          const res = await add_Baddress(data);
          
          if (res.status == 200) {
            
            navigate("/saved_address");   
            handleExceptionSuccessMessages(res);   
            }else{
              setsubmitbutton(true);
                handleExceptionError(res.response.data.ErrorMessage)
            }
          }
        
      };
    
    return(
        <div>
             <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      <Head_CateHead/>
            <Account_head/>
            <div class="sign_in_wrapper container account">
            <div class="container sign_container">
        <Slide_title title="Add address" className="add_address_page_title"/>
        <div class="sign_up_wrapper">
            <form onSubmit={handleSubmit}>
                <div className="checkout_form_tel">
                    <h3>Customer Name</h3>
                    <input type="text"value={data.BCustomerName}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BCustomerName: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BCustomerName: '' }));
                         }}/>
                         {validationErrors.BCustomerName && <div className="error">{validationErrors.BCustomerName}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Email</h3>
                    <input type="text"value={data.BEmail}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BEmail: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BEmail: '' }));
                         }}/>
                         {validationErrors.BEmail && <div className="error">{validationErrors.BEmail}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Number</h3>
                    <input type="text" value={data.BMobileNumber}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BMobileNumber: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BMobileNumber: '' }));
                         }}/>
                         {validationErrors.BMobileNumber && <div className="error">{validationErrors.BMobileNumber}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Country</h3>
                    <input type="text" value={data.BCountry}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BCountry: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BCountry: '' }));
                         }}/>
                         {validationErrors.BCountry && <div className="error">{validationErrors.BCountry}</div>}
                </div>
                <div className="group_input"> 
                    <div className="checkout_form_tel">
                        <h3>State</h3>
                        <input type="text" value={data.BState}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BState: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BState: '' }));
                         }}/>
                         {validationErrors.BState && <div className="error">{validationErrors.BState}</div>}
                    </div> 
                    <div className="checkout_form_tel">
                        <h3>City</h3>
                        <input type="text"value={data.BCity}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BCity: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BCity: '' }));
                         }}/>
                         {validationErrors.BCity && <div className="error">{validationErrors.BCity}</div>}
                    </div> 
                </div>
                <div className="checkout_form_tel">
                    <h3>Zip Code</h3>
                    <input type="text" value={data.BZipcode}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BZipcode: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BZipcode: '' }));
                         }}/>
                         {validationErrors.BZipcode && <div className="error">{validationErrors.BZipcode}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Address Line 1</h3>
                    <input type="text"value={data.BAddress1}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BAddress1: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BAddress1: '' }));
                         }}/>
                         {validationErrors.BAddress1 && <div className="error">{validationErrors.BAddress1}</div>}
                </div> 
                <div className="checkout_form_tel">
                    <h3>Address Line 2</h3>
                    <input type="text" value={data.BAddress2}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BAddress2: newValue }));
                         }}/>
                </div> 
                <br />
                <div class="order_box_delete">
                  {submitbutton?
                    <button style={{backgroundColor:'#01AEF0'}} type="submit">Add</button>
                    :
                    <button style={{backgroundColor:'#01AEF0'}} type="button">Add</button>
                  }
                </div>
                
            </form>
         </div>
      </div>
            </div>
            <Footer />
        </div>
    );
}
