import React, { useState, useEffect } from 'react';
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import { Category_Header } from "./../components/category-header.js";
import { useNavigate } from 'react-router-dom';
import Validation from '../components/validation.js';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { for_pass } from "../service/api.js";
import Head_CateHead from '../components/Head_CateHead.js';

export const Forgot_pass = () => {

  const navigate = useNavigate();
  const [isForgotPasswordSent, setIsForgotPasswordSent] = useState(false);
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);

  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError(ExceptionError => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  function handleExceptionSuccessMessages(res) {
    setSuccessMessages(successMessages => [
      ...successMessages,
      { id: Date.now(), message: res.data.Message },
    ]);
  }

  function clearErrors(id) {
    setExceptionError(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }




  const [values, setValues] = useState(
    {
      UserNameOrEmail: '',
    }
  )

  function handleInput(event) {
    const newObj = { ...values, [event.target.name]: event.target.value }
    setValues(newObj)
  }
  const handleHideSuccessMessage = () => {
    setIsForgotPasswordSent(false);
  };
  const forgot_api_call = async (values) => {
    if(values.UserNameOrEmail != ''){
      const res = await for_pass(values);

      if (res.status == 200) {
        var data = res.data;
        console.log(data);

        if (data.Message) {
          const urltoken = data.Message;
          // Navigate to Confirm_pass page with token in URL
         // navigate(`/Confirm_pass/${urltoken}`);
         
         setIsForgotPasswordSent(true);
         setTimeout(handleHideSuccessMessage, 4000);

        } else {
          handleExceptionSuccessMessages(res);
        }
        
      } else {
        handleExceptionError(res.data.ErrorMessage)
      }
      return (data);
    }
  };


  const [errors, setErrors] = useState({})

  function handleValidation(event) {
    event.preventDefault();
    const validationErrors = Validation(values); // Pass the values object directly
    setErrors(validationErrors);
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
        forgot_api_call(values);
    }
  }, [errors]);



 

  return (
    <div>
      <ErrorSnackbar
        errorMessages={ExceptionError}
        onClearErrors={clearErrors}
      />
      <SuccessSnackbar
        successMessages={successMessages}
        onclearSuccess={clearSuccess}
      />
      <Head_CateHead />
      <div className="container sign_container">
        <Slide_title title="Forgot Password" className="signin_page_title" />
        <div className="sign_in_wrapper">
        {isForgotPasswordSent && (
        <div className="alert success-alert">
          <p>Forgot password link has been sent to your email.</p>
        </div>
      )}
      {!isForgotPasswordSent && (
            <form onSubmit={handleValidation}>
              <div className="checkout_form_tel">
                <h3>Your Registered Email</h3>
                <input
                  type="email"
                  name="UserNameOrEmail"
                  id="UserNameOrEmail"
                  onChange={handleInput}
                />
                {errors.emailerr && (
                  <p className="giv_error">{errors.emailerr}</p>
                )}
              </div>
              <button className="frm_button form_submit_form" type="submit">
                Send link
              </button>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}