import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import { Account_head } from "./../components/account_head.js";
import { get_order_list } from "../service/api.js";
import Head_CateHead from '../components/Head_CateHead.js';

export const Acc_order_list = () => {
    const navigate = useNavigate();
    const [data, setdata] = useState([]);
    const data_get = async () => {
        try {
            const res = await get_order_list();
            console.log(res.data.Data);
            setdata(res.data.Data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            data_get();
        } else {
            navigate("/signin");
        }
    }, []);

    const viewOrder = (id) => {
        navigate(`/order_detail/${id}`);
    };
 const formatDateTime = (dateTimeString) => {
        const options = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,

        };

     const formattedDateTime = new Intl.DateTimeFormat('en-GB', options).format(new Date(dateTimeString));

        return formattedDateTime;
    };

    return (
        <div className='order_list_main'>
            <Head_CateHead />
            <Account_head title_="" />
            {data.length > 0 ?
            <>
                    <div class="sign_in_wrapper container account w-75">
                        <table width="100%" className='order_list_table'>
                            <tr>
                                <th>Order Id</th>
                                <th>Order Placed On</th>
                              
                                <th>status</th>
                                <th>Total</th>
                                {/* <th>Action</th> */}
                            </tr>
                            {data?.map((item, index) => (
                                <tr>
                                    <td style={{ cursor: 'pointer', color:'#01AEF0'}} onClick={() => viewOrder(item.OrderID)}>#{item.OrderID}</td>
                                    <td>{formatDateTime(item.CreatedOn)}</td>
                                    
                                    <td>{item.Status}</td>
                                    <td>${item.Totalprice}</td>
                                    {/* <td><button onClick={() => viewOrder(item.OrderID)} className='me-2'>View Order</button><button>Cancel</button></td> */}
                                </tr>
                            ))}
                        </table>

                    </div>
                </>
                :
                <div style={{textAlign:'center',marginTop:'-20px'}}>
                    <p style={{fontSize:'30px'}}>No orders placed yet.</p>
                    <br />
                    <Link to="/shop"> <button class="spe_ex_button">Shop</button></Link>
                    <br />
                    <br />
                    <br />
                </div>

            }
            <Footer />
        </div>
    );
}