import React, {useState,useEffect} from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import { Account_head } from "./../components/account_head.js";
import { change_pass } from "../service/api.js";
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import Head_CateHead from '../components/Head_CateHead.js';

export const Acc_change_pass = () => {
  const navigate = useNavigate();
    const [data, setData] = useState({
        OldPassword: '',
        NewPassword: '',
        cNewPassword: '',
      });
    
      const [validationErrors, setValidationErrors] = useState({
        OldPassword: '',
        NewPassword: '',
        cNewPassword: '',
      });
      const [ExceptionError, setExceptionError] = useState([]);
      const [successMessages, setSuccessMessages] = useState([]);
    
      function handleExceptionError(res) {
        // alert("sf");
        setExceptionError(ExceptionError => [
          ...ExceptionError,
          { id: Date.now(), message: res },
        ]);
      }
    
      function handleExceptionSuccessMessages(res) {
        setSuccessMessages(successMessages => [
          ...successMessages,
          { id: Date.now(), message: res.data.Message },
        ]);
      }
    
      function clearErrors(id) {
        setExceptionError(prevMessages =>
          prevMessages.filter(msg => msg.id !== id)
        );
      }
      function clearSuccess(id) {
        setSuccessMessages(prevMessages =>
          prevMessages.filter(msg => msg.id !== id)
        );
      }
    
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (data.OldPassword.trim() === '') {
          errors.OldPassword = 'Current Password is required.';
        }
        if (data.NewPassword.trim() === '') {
          errors.NewPassword = 'New Password is required.';
        } 
        if (data.cNewPassword.trim() === '') {
          errors.cNewPassword = 'Confirm Password is required.';
        }
        if (data.cNewPassword) {
            if (data.cNewPassword !== data.NewPassword) {
              errors.cNewPassword = 'Password is not matched.';
            }
          }
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
        setValidationErrors({});
        
          const res = await change_pass(data);
          if (res.status == 200){
        handleExceptionSuccessMessages(res);
        }else{
        handleExceptionError(res.response.data.ErrorMessage)
        }
      }


      useEffect(() => {
        if(!localStorage.getItem('token')){
          navigate("/signin");   
        }
    }, []);
    return(
        <div>
          <Head_CateHead/>
            <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
            <Account_head title_="Change Password"/>
            <div class="sign_in_wrapper container account">
                <form onSubmit={handleSubmit}>
                    <div className="checkout_form_tel">
                        <h3>Current Password</h3>
                        <input type="password"
                         value={data.OldPassword}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, OldPassword: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, OldPassword: '' }));
                         }}/>
                         {validationErrors.OldPassword && <div className="error">{validationErrors.OldPassword}</div>}
                    </div>
                    <div className="checkout_form_tel">
                        <h3> New Password</h3>
                        <input type="password"
                        value={data.NewPassword}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setData((prevData) => ({ ...prevData, NewPassword: newValue }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, NewPassword: '' }));
                        }}/>
                         {validationErrors.NewPassword && <div className="error">{validationErrors.NewPassword}</div>}

                    </div>
                    <div className="checkout_form_tel">
                        <h3> Confirm New Password</h3>
                        <input type="password"
                        value={data.cNewPassword}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setData((prevData) => ({ ...prevData, cNewPassword: newValue }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, cNewPassword: '' }));
                        }}/>
                         {validationErrors.cNewPassword && <div className="error">{validationErrors.cNewPassword}</div>}

                    </div> 
                    <button className="frm_button form_submit_form" type="submit">Update Password</button>
                </form>
            </div>
            <Footer />
        </div>
    );
}