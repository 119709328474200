import React from 'react';
import loaderimage from '../assets/img/homeimg/loader.gif';

const Loader = () => {
  return (
    <div className="loaderpage" style={{ position: 'absolute', width: '98.99vw', height: '100vh', background: 'rgba(255, 255, 255, 1)', zIndex: '99',textAlign:'center'}}>
      <img src={loaderimage} style={{top:'40vh',position:'relative',width:'80px'}} />
    </div>
  );
};

export default Loader;
