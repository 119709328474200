import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "../components/slide-title.js";
import { Account_head } from "../components/account_head.js";
import { Remove_btn } from "../components/remove_button.js";
// import { add_Baddress } from "../service/api.js";
// import { billing_address_detail } from "../service/api.js";
// import { Update_Baddress } from "../service/api.js";
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import Head_CateHead from '../components/Head_CateHead.js';

export const Contact = () => {
  const navigate = useNavigate();
    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const [submitbutton, setsubmitbutton] = useState(true);
    const {id} = useParams()
    function handleExceptionError(res) {
      // alert("sf");
      setExceptionError(ExceptionError => [
        ...ExceptionError,
        { id: Date.now(), message: res },
      ]);
    }
  
    function handleExceptionSuccessMessages(res) {
      setSuccessMessages(successMessages => [
        ...successMessages,
        { id: Date.now(), message: res.data.Message },
      ]);
    }
  
    function clearErrors(id) {
      setExceptionError(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
    function clearSuccess(id) {
      setSuccessMessages(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
    const [data, setData] = useState({
        BCustomerName: '',
        BCountry: '',
        BState: '',
        BCity: '',
        BAddress1: '',
        BAddress2: '',
        BEmail: '',
        BMobileNumber: '',
        BZipcode:'',
      });
    
      const [validationErrors, setValidationErrors] = useState({
        BCustomerName: '',
        BCountry: '',
        BState: '',
        BCity: '',
        BAddress1: '',
        BAddress2: '',
        BEmail: '',
        BMobileNumber: '',
        BZipcode:'',
      });
      
      useEffect(() => {
        if(!localStorage.getItem('token')){
          navigate("/signin");   
        }
      }, []);
      const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (data.BCustomerName.trim() === '') {
          errors.BCustomerName = 'Fullname is required.';
        }
        if (data.BCountry.trim() === '') {
          errors.BCountry = 'Message is required.';
        }
        if (data.BEmail.trim() === '') {
          errors.BEmail = 'Email is required.';
        }
        if (data.BEmail){
          const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
          if (!email_pattern.test(data.BEmail)) {
            errors.BEmail = "Invalid Email format";
        }
        }
        if (data.BMobileNumber.trim() === '') {                                                   
          errors.BMobileNumber = 'Subject is required.';
        }
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
        setValidationErrors({});
        setsubmitbutton(false);
        // if (id) {
        //   const res = await Update_Baddress(data,id);
        //   if (res.status == 200) {
        //       handleExceptionSuccessMessages(res);
        //       navigate(" /saved_address");   
        //     }else{
        //       setsubmitbutton(true);
        //         handleExceptionError(res.response.data.ErrorMessage)
        //     }
        //   }        
        // else {
        //   const res = await add_Baddress(data);
          
        //   if (res.status == 200) {
            
        //     navigate(" /saved_address");   
        //     handleExceptionSuccessMessages(res);   
        //     }else{
        //       setsubmitbutton(true);
        //         handleExceptionError(res.response.data.ErrorMessage)
        //     }
        //   }
        
      };
    
    return(
        <div>
             <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      <Head_CateHead/>
            <div class="sign_in_wrapper container account">
            <div class="container sign_container">
        <Slide_title title="Contact & Support" className="contact_title"/>
        <div class="sign_up_wrapper">
            <form onSubmit={handleSubmit}>
                <div className="checkout_form_tel">
                    <h3>Fullname</h3>
                    <input type="text"value={data.BCustomerName}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BCustomerName: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BCustomerName: '' }));
                         }}/>
                         {validationErrors.BCustomerName && <div className="error">{validationErrors.BCustomerName}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Email</h3>
                    <input type="text"value={data.BEmail}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BEmail: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BEmail: '' }));
                         }}/>
                         {validationErrors.BEmail && <div className="error">{validationErrors.BEmail}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Subject</h3>
                    <input type="text" value={data.BMobileNumber}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BMobileNumber: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BMobileNumber: '' }));
                         }}/>
                         {validationErrors.BMobileNumber && <div className="error">{validationErrors.BMobileNumber}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Message</h3>
                    <textarea type="text" value={data.BCountry}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, BCountry: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, BCountry: '' }));
                         }}/>
                         {validationErrors.BCountry && <div className="error">{validationErrors.BCountry}</div>}
                </div>
                <br />
                <div class="order_box_delete">
                  {submitbutton?
                    <button style={{backgroundColor:'#01AEF0'}} type="submit">Add</button>
                    :
                    <button style={{backgroundColor:'#01AEF0'}} type="button">Add</button>
                  }
                </div>
                
            </form>
         </div>
      </div>
            </div>
            <Footer />
        </div>
    );
}
