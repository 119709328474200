import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import { Account_head } from "./../components/account_head.js";
import { Remove_btn } from "./../components/remove_button.js";
import t_shirt from "./../assets/img/t-shirt.png";
import pdf from "./../assets/img/pdf.png";
import fill from "./../assets/img/fill.svg";
import upload from "./../assets/img/upload.svg";
import disc from "./../assets/img/disc.svg";
import remove from "./../assets/img/remove.svg";
import size from "./../assets/img/size.svg";
import { useParams } from "react-router";
import { get_order_detail } from "../service/api.js";
import { artwork_post } from "../service/api.js";
import CloseIcon from "@mui/icons-material/Close";
import Head_CateHead from "../components/Head_CateHead.js";

export const Acc_order_detail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [approve, setApprove] = useState("");
  const data_get = async (id) => {
    try {
      const res = await get_order_detail(id);
      console.log("res" , res)
      setdata(res.data.Data);
      console.log("artwork data ", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    data_get(id);
    console.log("artwork data ", data);
    if (data?.OrderArtworkAttachment?.length > 0) {
      // Access the Approve status of the first element
      const approveStatus = data.OrderArtworkAttachment[0].Approve;

      if (approveStatus === 2) {
        setApprove("Pending");
      } else if (approveStatus === 1) {
        setApprove("Approved");
      } else {
        setApprove("Reject");
      }
    }
  }, [id]);
  const [isModalOpen, setIsModalOpen] = useState({});
  const [isModalOpen2, setIsModalOpen2] = useState({});

  const toggleModal = (orderId, bool) => {
    if (bool) {
      // console.log('data'.data)
      setIsModalOpen({ id: orderId, show: bool });
      const allArt = data?.OrderCustomerAttachment?.filter(
        (art) => art.OrderDetailsID === orderId
      );
      console.log(data.OrderCustomerAttachment);
      setOpenedArtwork(allArt[0]);
      setAllArtwork([...allArt]);
      setArtIndex(0);
    } else {
      setIsModalOpen({});
      setOpenedArtwork({});
      setAllArtwork([]);
      setArtIndex(0);
    }
  };
  const toggleModal2 = (orderId, bool) => {
    if (bool) {
      setIsModalOpen2({ id: orderId, show: bool });
      const allArt = data?.OrderArtworkAttachment?.filter(
        (art) => art.OrderDetailsID === orderId
      );
      setOpenedArtwork(allArt[0]);
      setAllArtwork([...allArt]);
      setArtIndex(0);
    } else {
      setIsModalOpen2({});
      setOpenedArtwork({});
      setAllArtwork([]);
      setArtIndex(0);
    }
  };

  const [artwork, setartwork] = useState(1);

  const handelartwork = (value) => {
    setartwork(value);
  };
  useEffect(() => {}, [artwork]);
  const [finishart, setfinishart] = useState(false);
  const [showArtWork, setShowArtwork] = useState(false);
  const [artIndex, setArtIndex] = useState(0);


  const handlePrevNext = (direction) => {
    // Logic to handle previous and next buttons
    // Update artIndex accordingly
    if (direction === "prev" && artIndex > 0) {
      setArtIndex(artIndex - 1);
    } else if (direction === "next" && artIndex < allArtwork.length - 1) {
      setArtIndex(artIndex + 1);
    }
  };
  const handleShowArtwork = (index) => {
    const pdfUrl = process.env.REACT_APP_API_URL + allArtwork[index]?.Attachment;
  
    window.open(pdfUrl, '_blank');
  };


  const artwork_api = async (artworkid, status, orderDetailsId) => {
    try {
      const res = await artwork_post(data, status, artworkid, orderDetailsId);
      if (res.status == 200) {
        setfinishart(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getApproveStatus = (approve) => {
    if (approve === 2) {
      return "Pending";
    } else if (approve === 1) {
      return "Approved";
    } else {
      return "Reject";
    }
  };

  const arr = [1, 2, 3, 4];

  const renderPDFViewer = (base64Data, artworkId, index) => {
    try {
      const decodedData = atob(base64Data);
      const blob = new Blob([decodedData], { type: "application/pdf" });
      return (
        <div>
          <object
            data={URL.createObjectURL(blob)}
            type="application/pdf"
            width="100%"
            height="500px"
          >
            <p>
              Unable to display PDF file.{" "}
              <a
                href={URL.createObjectURL(blob)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
              instead.
            </p>
          </object>
          <div className="order_box_delete artwork_btn">
            {data.OrderArtworkAttachment[index]?.Approve === 1 ||
            data.OrderArtworkAttachment[index]?.Approve === 0 ? (
              <>
                <p>Artwork approved by John</p>
              </>
            ) : (
              <>
                <button
                  style={{ backgroundColor: "#01AEF0" }}
                  onClick={() =>
                    artwork_api(
                      data.OrderArtworkAttachment[index]
                        ?.OrderArtworkAttachmentID,
                      1
                    )
                  }
                >
                  Accept
                </button>
                <button
                  style={{ backgroundColor: "red" }}
                  onClick={() =>
                    artwork_api(
                      data.OrderArtworkAttachment[index]
                        ?.OrderArtworkAttachmentID,
                      0
                    )
                  }
                >
                  Reject
                </button>
              </>
            )}
          </div>
        </div>
      );
    } catch (error) {
      console.error("Error decoding base64 data:", error);
      return <p>Error decoding PDF attachment</p>;
    }
  };

  const [openedArtwork, setOpenedArtwork] = useState("");
  const [allArtwork, setAllArtwork] = useState();
  return (
    <div>
      <Head_CateHead />
      <Account_head />
      <div className="sign_in_wrapper container account">
        <Slide_title
          title="Order Information"
          className="order_detail_page_title"
        />
        <div className="order_detail_wrapper order">
          <h3>OrderID: #{id}</h3>
          <p>Thank You for shopping with us.</p>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "33.33%" }}>
              <h4>Shipping Address</h4>
              <p>
                {data.SCustomerName}
                <br />
                {data.SAddress1} {data.SAddress2}
                <br />
                {data.SCity}
                <br />
                {data.SState}
                <br />
                {data.SCountry} - {data.SZipcode}
              </p>
            </div>
            <div style={{ width: "33.33%" }}>
              <h4>Billing Address</h4>
              <p>
                {data.BCustomerName}
                <br />
                {data.BAddress1} {data.BAddress2}
                <br />
                {data.BCity}
                <br />
                {data.BState}
                <br />
                {data.BCountry} - {data.BZipcode}
                <br />
                {data.BMobileNumber}
                <br />
                {data.BEmail}
              </p>
            </div>
            <div style={{ width: "33.33%" }}>
              <h4>Payment Method</h4>
              <p>
                Payment Method: {data.PaymentMethod}
                <br />
                Order Status: {data.Status}
              </p>
            </div>
          </div>
          <div className="cart_wrap order_detail_wrap">
            <div className="cart_list order">
              {data?.OrderDetails?.map((item) => (
                <div className="cart_item">
                  <div className="cart_img">
                    <img
                      src={`https:///api.bebranded.com.au/${item.Product?.ProductImage}`}
                    />
                  </div>
                  <div className="cart_info">
                    <div className="cart_info1">
                      <div className="cart_detail order">
                        <div className="cart_title">
                          <h3>
                            {item.ProductName} <br /> QTY :- {item.QTY} X $
                            {item.ProductPrice}
                          </h3>
                          <div className="cart_color_size">
                            {/* <span><img src={fill} />Red<hr /><img src={size} />XL</span> */}
                            <span>Brief : {item.Brief}</span>
                          </div>
                        </div>
                        <div className="cart_info2">
                          <button
                            className={`cart_info2_bt1 ${
                              !data.OrderCustomerAttachment ||
                              data.OrderCustomerAttachment.length === 0
                                ? "disabled-button"
                                : ""
                            }`}
                            disabled={
                              !data.OrderCustomerAttachment ||
                              data.OrderCustomerAttachment.length === 0
                            }
                            onClick={() =>
                              toggleModal(item.OrderDetailsID, true)
                            }
                          >
                            Client ArtWork
                          </button>
                          <button
                            className={`cart_info2_bt1 ${
                              !data.OrderArtworkAttachment ||
                              data.OrderArtworkAttachment.length === 0
                                ? "disabled-button"
                                : ""
                            }`}
                            disabled={
                              !data.OrderArtworkAttachment ||
                              data.OrderArtworkAttachment.length === 0
                            }
                            onClick={() =>
                              toggleModal2(item.OrderDetailsID, true)
                            }
                          >
                            Artwork Approve
                          </button>
                          {isModalOpen.id === item.OrderDetailsID && (
                            <div className="modal z-1">
                              <div className="order_box_delete">
                                <div>
                                  <p>Artwork</p>
                                </div>
                                <button
                                  style={{ backgroundColor: "red" }}
                                  onClick={() =>
                                    toggleModal(item.OrderDetailsID, false)
                                  }
                                >
                                  <CloseIcon />
                                </button>
                              </div>
                              {allArtwork.length > 0 ? (
                                <div className="artwork_wrapper">
                                  <div className="artwork_icons">
                                    {allArtwork.map((artwork, index) => (
                                      <img
                                        key={index}
                                        src={pdf}
                                        alt={`pdf-${index + 1}`}
                                        onClick={() => handleShowArtwork(index)}
                                        style={{
                                          cursor: "pointer",
                                          height: "60px",
                                        }}
                                      />
                                    ))}
                                  </div>
                                  {showArtWork && (
                                    <div className="w-100 atwork_div">
                                      <div className="d-flex pre_ifrem_nex">
                                        <iframe
                                          src={
                                            process.env.REACT_APP_API_URL +
                                            openedArtwork?.Attachment
                                          }
                                          frameBorder="0"
                                          scrolling="no"
                                          height="100%"
                                          width="95%"
                                          overflow="hidden"
                                        ></iframe>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <h2 className="text-danger">
                                  No Artwork Attechment
                                </h2>
                              )}
                            </div>
                          )}
                          {isModalOpen2.id === item.OrderDetailsID && (
                            <div className="modal z-1">
                              <div className="order_box_delete">
                                <div>
                                  <p>Artwork</p>
                                </div>
                                <button
                                  style={{ backgroundColor: "red" }}
                                  onClick={() =>
                                    toggleModal2(item.OrderDetailsID, false)
                                  }
                                >
                                  <CloseIcon />
                                </button>
                              </div>
                              {allArtwork.length > 0 ? (
                                <div className="artwork_wrapper">
                                  <div className="artwork_icons">
                                    {allArtwork.map((artwork, index) => (
                                      <div className="artworks">
                                      <img
                                        key={index}
                                        src={pdf}
                                        alt={`pdf-${index + 1}`}
                                        onClick={() => handleShowArtwork(index)}
                                        style={{
                                          cursor: "pointer",
                                          height: "60px",
                                        }}
                                      />
                                             <p>Status : {getApproveStatus(data.OrderArtworkAttachment[index].Approve)}</p>

                                      </div>
                                      
                                    ))}
                                    
                                  </div>

                                  {showArtWork && (
                                    <div className="w-100 atwork_div">
                                      <div className="d-flex pre_ifrem_nex">
                                        <iframe
                                          src={
                                            process.env.REACT_APP_API_URL +
                                            allArtwork[artIndex]?.Attachment
                                          }
                                          frameBorder="0"
                                          scrolling="no"
                                          height="100%"
                                          width="95%"
                                          overflow="hidden"
                                        ></iframe>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <h2 className="text-danger">
                                  No Artwork Attachment
                                </h2>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="cart_price">
                          <h5>${data.Totalprice}</h5>
                          {/* <h5>${(item.ProductPrice * item.QTY).toFixed(2)}</h5> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <hr className="cart_item_space" />
            </div>
            <div
              className="cart_total order"
              style={{ marginRight: "0", width: "250px" }}
            >
              <div className="cart_title">
                <h3>Order Summary</h3>
              </div>
              <div className="cart_total_list">
                <div className="cart_count1">
                  <h3>SubTotal</h3>
                  <h4>
                    $
                    {(data.Totalprice / (1 - data.CouponValue / 100)).toFixed(
                      2
                    )}
                  </h4>
                </div>
                <hr className="cart_total_hr" />
                {/* {data.OrderSetupPrice ? (
                  data.OrderSetupPrice.map((item, index) => (
                    <>
                      <div className="cart_count1" key={index}>
                        <h3>{item.SetupName}</h3>
                        <h4>${item.SetupPrice}</h4>
                      </div>
                      <hr className="cart_total_hr" />
                    </>
                  ))
                ) : (
                  <></>
                )} */}
                {Number(data.CouponValue) > 0 && (
                  <>
                    <div className="cart_count1">
                      <h3>Coupon Discount : "({data.CouponName})"</h3>
                      <h4>-${data.CouponValue}</h4>
                    </div>
                    <hr className="cart_total_hr" />
                  </>
                )}
                <div className="cart_count1">
                  <h3>GST ({data.GSTText}%)</h3>
                  <h4>${data.GSTAmount}</h4>
                </div>
                <hr className="cart_total_hr" />
                <div className="cart_count1">
                  <h3>Order total</h3>
                  <h4>${data.Totalprice}</h4>
                </div>
              </div>
              {/* <p>Learn more <a href='#'>Taxes</a> and <a href="#">Shipping</a> information</p> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

{
  /* <div className='artwork_div'>
<img className='artwork_image_in_model' src={`https://api.bebranded.com.au/${image.Attachment}`} />
<div className='atwork_model_btn'>
<button style={{ backgroundColor: '#01AEF0' }} className='cart_info2_bt1 aprove_btn' onClick={()=>artwork_api(image.OrderCustomerAttachmentID,1,image.OrderDetailsID)}>Approve</button><button className='reject_btn cart_info2_bt1'>Reject</button>
</div>
</div> */
}
