import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "./../App.css";
import logo from "./../assets/img/Logo.png";
import search from "./../assets/img/search.svg";
import call from "./../assets/img/call.svg";
import CloseIcon from '@mui/icons-material/Close';
import email from "./../assets/img/email.png";
import { Link } from "react-router-dom";
import profile from "./../assets/img/profile.svg";
import cart from "./../assets/img/cart.svg";
import Box from "@mui/material/Box";
import MenuIcon from '@mui/icons-material/Menu';
import { display, alignItems } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { home_search, addtocart_get_api, portal_map_list, AllUnMapcategory } from "../service/api.js";
import Loader from "./loader.js";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
function YourComponent({ menuItem }) {
  return (
    <Box
      component="ul"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      className="all_products_2 hover_open_menu_1"
    >
      {menuItem.SubCategory.map((childmenu, index2) => (
        <li key={index2}>
          <Link to={`/${menuItem.Slug}/${childmenu.Slug}`}>
            <span dangerouslySetInnerHTML={{ __html: childmenu.SubCategoryName }} />
          </Link>
        </li>
      ))}
    </Box>
  );
}

export default YourComponent;

export const Header = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(""); // State for the search input
  const [searchArray, setSearchArray] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [api_data, setapi_data] = useState([]);
  const [portalItems, setportalItems] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();

  //  const handleResize = () => {
  //    setIsMobile(window.innerWidth <= 520);
  //  };
  //   useEffect(() => {

  //     // Add event listener for window resize
  //     window.addEventListener("resize", handleResize);

  //     // Initial check on component mount
  //     // handleResize();


  //     // Remove event listener on component unmount
  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, [location.pathname]);
  const [submenuStates, setSubmenuStates] = useState({});

  const toggleSubMenu = (menuItemSlug) => {
    setSubmenuStates((prevStates) => ({
      ...prevStates,
      [menuItemSlug]: !prevStates[menuItemSlug],
    }));
  };
  useEffect(() => {
    console.log(window.innerWidth);
    //     handleResize();
    if (window.innerWidth <= 900) {
      setIsMobile(true);
    }
  }, [location.pathname]);


  const handleSearchChange = async (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    try {
      // Make an API request with the search term
      const res = await home_search({ Search: searchTerm });
      const fetchsearchdata = res.data.Data;
      setSearchArray(fetchsearchdata); // Update the search results state with the response data
      setShowResults(true); // Show search results when there's data
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      const searchResultsElement = document.querySelector(".search-results");
      if (
        searchResultsElement &&
        !searchResultsElement.contains(event.target)
      ) {
        setShowResults(false); // Hide search results when clicking outside
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [menuItems, setMenuItems] = useState([]);
  const [menuItems2, setMenuItems2] = useState([]);
  const [menuItemsapperal, setMenuItemsapperal] = useState([]);
  const [MenuItemscollection, setMenuItemscollection] = useState([]);
  const [menuItemsbrand, setmenuItemsbrand] = useState([]);
  useEffect(() => {
    const fetchCategoryItems = async () => {
      try {
        const response = await AllUnMapcategory(); // Fetch category data

        const categoryItemsData = response.data.Data; // Access the 'Data' array
        console.log(response.data.Data);
        setMenuItemsapperal(response.data.Data[0])
        setMenuItemscollection(response.data.Data[4])
        setmenuItemsbrand(response.data.Data[2])
        setMenuItems(categoryItemsData);
        console.log(response.data.Data);
        // const chunkSize = 6;
        // var chunkedArray = [];

        // for (let i = 0; i < categoryItemsData.length; i += chunkSize) {
        //   const chunk = categoryItemsData.slice(i, i + chunkSize);
        //   chunkedArray.push(chunk);
        // }
        setMenuItems2(categoryItemsData)
      } catch (error) {
        console.error("Error fetching category items", error);
      }
    };
    fetchCategoryItems(); // Call the function to fetch category data
  }, []);

  const [cartData, setCartData] = useState(parseInt(localStorage.getItem("cartItemCount")) || 0);

  useEffect(() => {
    getCartData();
  }, [localStorage.getItem("cartItemCount")]);

  const getCartData = async () => {
    try {
      const cartApiData = await addtocart_get_api();

      if (Array.isArray(cartApiData.Data)) {
        const newCartData = cartApiData.Data.length;
        setCartData(newCartData);
        localStorage.setItem("cartItemCount", newCartData);
      } else {
        console.error("API data format is not as expected");
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };



  // const countCartItems = () => {
  //   return api_data.length;
  // };

  // // State for cart item count
  // const [cartItemCounts, setCartItemCount] = useState(countCartItems());

  // // Update cart item count when the cart data changes
  // useEffect(() => {
  //   setCartItemCount(countCartItems());
  //   localStorage.setItem("cartItemCount", countCartItems());
  // }, [api_data]);


  // const cartItemCount = localStorage.getItem("cartItemCount") || 0;
  const isAuthenticated = !!localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(true);

  // Simulate page loading for 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4200);

    return () => clearTimeout(timer);
  }, []);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    navigate("/signin");
  };

  useEffect(() => {
    const portallist = async () => {
      try {
        const response = await portal_map_list(); // Fetch category data
        const ItemsData = response.data.data; // Access the 'Data' array
        setportalItems(ItemsData);
      } catch (error) {
        console.error("Error fetching portal list", error);
      }
    };

    if (localStorage.getItem("token")) {
      portallist(); // Call the function to fetch category data
    }
  }, []);
  const [isClicked, setIsClicked] = useState(false);
  const handlemenu = () => {
    setIsClicked(true);
  }
  const handlemenuclose = () => {
    setIsClicked(false);
  }
  useEffect(() => {
    const fetchCategoryItems = async () => {
      try {
        const response = await AllUnMapcategory(); // Fetch category data

        const categoryItemsData = response.data.Data; // Access the 'Data' array

        setMenuItems(categoryItemsData);
        // const chunkSize = 6;
        // var chunkedArray = [];

        // for (let i = 0; i < categoryItemsData.length; i += chunkSize) {
        //   const chunk = categoryItemsData.slice(i, i + chunkSize);
        //   chunkedArray.push(chunk);
        // }
        setMenuItems2(categoryItemsData)
      } catch (error) {
        console.error("Error fetching category items", error);
      }
    };
    fetchCategoryItems(); // Call the function to fetch category data
  }, []);
  return (
    <header>
      {isLoading && (
        <Loader />
      )}
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="container"
      >
        <div className="header-logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        {!isMobile ? (
          <>
            {/* <div className="search-container">
          <div className="search-feild">
            <input
              placeholder="Search for product"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <img src={search} className="search-icon" />
          </div>

          {searchTerm && (
            <div className="search-results" style={{ display: showResults ? "block" : "none" }}>
              {searchArray.Product?.length === 0 &&
                searchArray.SubCategory?.length === 0 ? (
                <div>No results found.</div>
              ) : (
                <ul>
                  {searchArray.Product?.length > 0 && (
                    <li>
                      <strong>Product</strong>
                      <ul>
                        {searchArray.Product.map((product, index) => (
                          <li key={index}><Link to={`/product/${product.ProductID}`}>{product.ProductName}</Link></li>
                        ))}
                      </ul>
                    </li>
                  )}
                  {searchArray.SubCategory?.length > 0 && (
                    <li>
                      <strong>Category</strong>
                      <ul>
                        {searchArray.SubCategory.map((subCategory, index) => (
                          <li key={index}> <Link to={`/category/${subCategory.SubCategoryID}`}>{subCategory.SubCategoryName}</Link></li>
                        ))}
                      </ul>
                    </li>
                  )}
                </ul>
              )}
            </div>
          )}
        </div> */}

            <div>
              <nav>
                <Box
                  component="ul"
                  sx={{ display: "flex", alignItems: "center" }}
                  className="header-ul"
                >
                  {/* <Link to="tel:1300115733">
                <Box
                  component="li"
                  sx={{ display: "flex", alignItems: "center" }}
                  className="call-li"
                >
                  <img src={call} className="call-icon" />
                  1300 11 57 33
                </Box>
              </Link>
              <Link to="mailto:info@bebranded.com.au">
                <Box
                  component="li"
                  sx={{ display: "flex", alignItems: "center" }}
                  className="call-li"
                  style={{ marginLeft: '1vw', marginRight: '0' }}
                >
                  <img src={email} className="call-icon" />{" "}
                  info@bebranded.com.au
                </Box>  
              </Link> */}


                  {/* Conditionally render profile and cart links */}
                  {isAuthenticated ? (
                    <>

                      {/* {portalItems.length > 0 ?
                    (portalItems.length == 1 ?
                      <li style={{ cursor: 'pointer', marginLeft: '0.5vw', marginRight: '0.5vw' }}>
                        <Link to={`/${portalItems[0].Slug}`} style={{ color: '#01AEF0' }}>{portalItems[0].PortalName}</Link>
                      </li>
                      :
                      <li style={{ cursor: 'pointer', marginLeft: '1vw', marginRight: '1vw' }}>
                        {localStorage.getItem("token") ? <Link to="#" style={{ color: '#01AEF0' }}>Portals</Link> : ""}
                        <ul className="child_menu cate-header-ul" style={{ marginTop: 0 }}>
                          {portalItems.map((menuItem, index) => (
                            <li key={index}>
                              <Link to={`/${menuItem.Slug}`}>{menuItem.PortalName}</Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                    :
                    <></>
                  } */}


                      <Box onClick={logout}
                        component="li"
                        sx={{ display: "flex", alignItems: "center" }}
                        className="call-li"
                        style={{ cursor: 'pointer', marginLeft: '0.5vw', marginRight: '1vw' }}
                      >
                        Logout
                      </Box>
                      <li>
                        <Link to="/account">
                          <div className="profile-icon">
                            <img src={profile} alt="Profile" />
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/cart">
                          <div className="cart-icon">
                            <img src={cart} alt="Cart" />
                            <span className="cart-badge">{cartData}</span>
                          </div>
                        </Link>
                      </li>
                    </>
                  ) :
                    <Link to="/signin">
                      <Box
                        component="li"
                        sx={{ display: "flex", alignItems: "center" }}
                        className="header_signup_button"
                      >
                        Sign In
                      </Box>
                    </Link>
                  }
                </Box>
              </nav>
            </div>
          </>) : (
          <>
            <MenuIcon onClick={handlemenu} />
            <div className={`mobile_menu_wrapper ${isClicked ? "active" : ""}`}>
              <div className="mobilemenu_close_icon">
                <CloseIcon onClick={handlemenuclose} />
              </div>
              <div className="search-container">
                <div className="search-feild">
                  <input
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <img src={search} className="search-icon" />
                </div>

                {searchTerm && (
                  <div className="search-results" style={{ display: showResults ? "block" : "none" }}>
                    {searchArray.Product?.length === 0 &&
                      searchArray.SubCategory?.length === 0 ? (
                      <div>No results found.</div>
                    ) : (
                      <ul>
                        {searchArray.Product?.length > 0 && (
                          <li>
                            <strong>Product</strong>
                            <ul style={{ overflow: 'auto', height: 'auto' }}>
                              {searchArray.Product.map((product, index) => (
                                <li key={index} style={{ width: '33.33%' }}>
                                  <Link to={`/product/${product.Slug}`} style={{ color: '#01AEF0', fontWeight: '600', width: '100%', fontSize: ' 14px' }}>
                                    <img src={process.env.REACT_APP_API_URL + product.ProductImage} style={{ width: '100%', height: 'auto' }} />
                                    {product.ProductName}<br />
                                    <span style={{ fontSize: '11px', color: 'rgb(37, 37, 37)', fontWeight: '500', position: 'relative', top: '5px' }}>SKU : {product.SKU}</span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
              </div>

              <div>
                <nav>
                  <Box
                    component="ul"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="cate-header-ul"
                  >
                    <li className="all_products">
                      <Link to="#" onClick={() => toggleSubMenu("products")}>Products</Link>
                      <Box
                        component="ul"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className={`all_products_1 hover_open_menu ${submenuStates["products"] ? "open" : ""
                          }`}
                      >
                        {menuItems2.map((menuItem, index) => (
                          <>
                            {menuItem.Slug != 'apparel' && menuItem.Slug != 'brands' && menuItem.Slug != 'collections' && (
                              <>
                                <li className="sub_cate_menu_">
                                  {/* <Link to={`/category/${menuItem.Slug}`}> */}
                                  <Link to={`Js:`}>
                                    {menuItem.CategoryName}
                                    <svg height="16" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
                                    </svg>
                                  </Link>
                                  <YourComponent menuItem={menuItem} />
                                </li>
                              </>
                            )}
                          </>

                        ))}
                      </Box>

                    </li>
                    <li>
                      {/* <Link to="#">Apparel</Link> */}
                      <li className="all_products">
                        <Link to="#" onClick={() => toggleSubMenu("Apparel")}>Apparel</Link>
                        <Box
                          component="ul"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className={`all_products_1 hover_open_menu ${submenuStates["Apparel"] ? "open" : ""
                            }`}
                        >
                          {menuItemsapperal.SubCategory?.map((menuItem, index) => (
                            <>
                              {menuItem.Slug != 'apparel' && menuItem.Slug != 'brands' && menuItem.Slug != 'collections' && (
                                <>
                                  <li className="sub_cate_menu_">
                                    <Link to={`/category/${menuItem.Slug}`}>{menuItem.SubCategoryName}

                                    </Link>
                                  </li>
                                </>
                              )}
                            </>

                          ))}
                        </Box>

                      </li>
                    </li>
                    {/* <li>
                        <Link to="#">Indent</Link>
                      </li> */}
                    <li>
                      <Link to="/products/new">New</Link>
                    </li>

                    {/* <Link to="#">Brand</Link> 
                        */}
                    <li>
                      {/* <Link to="#">Apparel</Link> */}
                      <li className="all_products">
                        <Link to="#" onClick={() => toggleSubMenu("Brand")}>Brand</Link>
                        <Box
                          component="ul"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className={`all_products_1 hover_open_menu ${submenuStates["Brand"] ? "open" : ""
                            }`}
                        >
                          {menuItemsbrand.SubCategory?.map((menuItem, index) => (
                            <>
                              {menuItem.Slug != 'apparel' && menuItem.Slug != 'brands' && menuItem.Slug != 'collections' && (
                                <>
                                  <li className="sub_cate_menu_">
                                    <Link to={`/category/${menuItem.Slug}`}><span dangerouslySetInnerHTML={{ __html: menuItem.SubCategoryName }} />

                                    </Link>
                                  </li>
                                </>
                              )}
                            </>

                          ))}
                        </Box>
                      </li>
                    </li>
                    {/* {menuItemsbrand.SubCategory ? (
                            menuItemsbrand.SubCategory.length === 0 ? (
                              <li style={{ cursor: 'pointer' }} className="all_products">
                                <Link to={`/${menuItemsbrand.Slug}`}>Brand</Link>
                              </li>
                            ) : (
                              <li style={{ cursor: 'pointer' }} className="all_products">
                                {localStorage.getItem("token") ? <Link to="#">Brand</Link> : ""}
                                <ul className="child_menu cate-header-ul all_products_1 hover_open_menu portal" style={{ marginTop: 0 }}>
                                  {menuItemsbrand.SubCategory.map((menuItem, index) => (
                                    <li key={index} className="sub_cate_menu_ apparel">
                                      <Link to={`/${menuItem.Slug}`}>{menuItem.SubCategoryName}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            )
                          ) : (
                            <></>
                          )} */}


                    <li>
                      <li className="all_products">
                        <Link to="#" onClick={() => toggleSubMenu("collections")}>Collections</Link>
                        <Box
                          component="ul"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className={`all_products_1 hover_open_menu ${submenuStates["collections"] ? "open" : ""
                            }`}
                        >
                          {MenuItemscollection.SubCategory?.map((menuItem, index) => (
                            <>
                              {menuItem.Slug != 'apparel' && menuItem.Slug != 'brands' && menuItem.Slug != 'collections' && (
                                <>
                                  <li className="sub_cate_menu_">
                                    <Link to={`/category/${menuItem.Slug}`}>{menuItem.SubCategoryName}

                                    </Link>
                                  </li>
                                </>
                              )}
                            </>

                          ))}
                        </Box>
                      </li>
                    </li>
                    {/* <li>
                        <Link to="#">Sale</Link>
                      </li> */}
                    {/* <li> */}
                    {isAuthenticated && (
                      <>
                        {portalItems.length > 0 ? (
                          portalItems.length === 1 ? (
                            <li style={{ cursor: 'pointer' }} className="all_products">
                              <Link to={`/${portalItems[0].Slug}`}>{portalItems[0].PortalName}</Link>
                            </li>
                          ) : (
                            <li style={{ cursor: 'pointer' }} className="all_products">
                              {localStorage.getItem("token") ? (
                                submenuStates ? (
                                  <Link to="#" onClick={() => toggleSubMenu("portals")}>
                                    Portals
                                  </Link>
                                ) : (
                                  <Link to="#" onClick={() => toggleSubMenu("portals")}>
                                    Portals
                                  </Link>
                                )

                              ) : null}

                              <ul
                                className={`child_menu cate-header-ul all_products_1 hover_open_menu portal ${submenuStates["portals"] ? "open" : ""
                                  }`}
                                style={{ marginTop: 0 }}
                              >
                                {portalItems.map((menuItem, index) => (
                                  <li key={index} className="sub_cate_menu_" onClick={() => {
                                    navigate(`/${menuItem.Slug}`);
                                    console.log("d");
                                  }} >
                                    <Link to={`*/${menuItem.Slug}`}>{menuItem.PortalName}</Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    {isAuthenticated && (
                      <Box onClick={logout}
                        component="li"
                        className="call-li"
                        style={{ cursor: 'pointer' }}
                      >
                        <Link to="#">Logout</Link>
                      </Box>)}
                    {/* </li> */}

                  </Box>
                  <Box
                    component="ul"
                    sx={{ display: "flex", flexDirection: "column" }}
                    className="header-ul"
                  >
                    {/* <Link to="tel:1300115733">
                        <Box
                          component="li"
                          sx={{ display: "flex", alignItems: "center" }}
                          className="call-li"
                        >
                          <img src={call} className="call-icon" />
                          1300 11 57 33
                        </Box>
                      </Link> */}
                    {/* <Link to="mailto:info@bebranded.com.au">
                        <Box
                          component="li"
                          sx={{ display: "flex", alignItems: "center" }}
                          className="call-li"
                        >
                          <img src={email} className="call-icon" />{" "}
                          info@bebranded.com.au
                        </Box>
                      </Link> */}


                    {/* Conditionally render profile and cart links */}
                    {isAuthenticated ? (
                      <>

                        {/* {portalItems.length > 0 ?
                            (portalItems.length == 1 ?
                              <li style={{ cursor: 'pointer', marginLeft: '0.5vw', marginRight: '0.5vw' }}>
                                <Link to={`/portal/${portalItems[0].PortalID}`} style={{ color: '#01AEF0' }}>{portalItems[0].PortalName}</Link>
                              </li>
                              :
                              <li style={{ cursor: 'pointer', marginLeft: '1vw', marginRight: '1vw' }}>
                                {localStorage.getItem("token") ? <Link to="#" style={{ color: '#01AEF0' }}>Portals</Link> : ""}
                                <ul className="child_menu cate-header-ul" style={{ marginTop: 0 }}>
                                  {portalItems.map((menuItem, index) => (
                                    <li key={index}>
                                      <Link to={`/portal/${menuItem.PortalID}`}>{menuItem.PortalName}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            )
                            :
                            <></>
                          } */}



                        <li>
                          <Link to="/account">
                            <div className="profile-icon">
                              <img src={profile} alt="Profile" />
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/cart">
                            <div className="cart-icon">
                              <img src={cart} alt="Cart" />
                              <span className="cart-badge">{cartData}</span>
                            </div>
                          </Link>
                        </li>
                      </>
                    ) :
                      <Link to="/signin">
                        <Box
                          component="li"
                          sx={{ display: "flex", alignItems: "center" }}
                          className="header_signup_button"
                        >
                          Sign In
                        </Box>
                      </Link>
                    }
                  </Box>
                </nav>
              </div>
            </div>
          </>
        )
        }
      </Box>
    </header>
  );
};
