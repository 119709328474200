import React, { useState, useEffect, useRef } from "react";
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "../components/slide-title.js";
import t_shirt from "./../assets/img/t-shirt.png";
import fill from "./../assets/img/fill.svg";
import { Link } from "react-router-dom";
import upload from "./../assets/img/upload.svg";
import emptycart from "./../assets/img/cart_empty.jpg";
import disc from "./../assets/img/disc.svg";
import remove from "./../assets/img/remove.svg";
import size from "./../assets/img/size.svg";
import { Category_Header } from "../components/category-header.js";
import {
  addtocart_get_api,
  addtocart_delete_api,
  gstdata_get_api,
  cart_description_api,
  cart_artwork_api,
  delete_artwork_api,
} from "../service/api.js";
import ErrorSnackbar from "../components/ErrorSnackbar.js";
import SuccessSnackbar from "../components/SuccessSnackbar.js";
import Head_CateHead from "../components/Head_CateHead.js";
import Alert from "../components/Alert.js";

export const Cart = () => {
  const [api_data, setapi_data] = useState([]);
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const fileInputRef = useRef(null);

  //   useEffect(() => {
  //     // Retrieve the JSON string from local storage
  //     const storedFilesString = localStorage.getItem("selectedFiles");

  //     // Parse the JSON string to get the stored files object
  //     const storedFilesObject = storedFilesString ? JSON.parse(storedFilesString) : {};

  //     // Set the stored files in the state
  //     setSelectedFiles(storedFilesObject);
  //   }, []);

  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError((ExceptionError) => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  function handleExceptionSuccessMessages(res) {
    setSuccessMessages((successMessages) => [
      ...successMessages,
      { id: Date.now(), message: res.Message },
    ]);
  }

  function clearErrors(id) {
    setExceptionError((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  const fetchData = async () => {
    try {
      const cartApiData = await addtocart_get_api();

      // Ensure that cartApiData.Data is an array before setting the state
      if (Array.isArray(cartApiData.Data)) {
        setapi_data(cartApiData.Data);
        setCartData(cartApiData.Data.length);
        console.log("cart api data ", cartApiData.Data);
      } else {
        console.error("API data format is not as expected");
      }
      // Fetch the GST rate
      fetchGST();
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };
  useEffect(() => {
 
    fetchData();
  }, []);

  // const countCartItems = () => {
  //     return api_data.length;
  // };

  // // State for cart item count
  // const [cartItemCount, setCartItemCount] = useState(countCartItems());
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [cartData, setCartData] = useState(
    localStorage.getItem("cartItemCount")
  );
  // useEffect(()=> {
  //     getCartData()

  //   },[cartData])

  // // Update cart item count when the cart data changes
  // useEffect(() => {
  //     setCartItemCount(countCartItems());
  //     var existingCount = localStorage.getItem("cartItemCount");
  //     var newCount = countCartItems(); // Calculate the new value
  //     localStorage.setItem("cartItemCount", newCount);
  // }, [api_data]);

  // Function to remove a cart item by cartID
  const removeCartItem = async (cartID) => {
    console.log("open please");
    setShowConfirmBox(true);
    setRemoveId(cartID);
  };
  const handleDelete = async (id) => {
    try {
      console.log("id for delete ", id);
      const response = await addtocart_delete_api(id);
      // handleExceptionSuccessMessages(response.Message);
      // Update the cart items after removal
      const updatedCartItems = api_data.filter(
        (item) => item.Carts[0].CartID !== id
      );
      setapi_data(updatedCartItems);
      setCartData(updatedCartItems.length);

      // getCartData()
      setShowConfirmBox(false);
      // Update cart item count in local storage
      localStorage.setItem("cartItemCount", updatedCartItems.length);
    } catch (error) {
      console.error("Error removing cart item:", error);
    }
  };
  const [gstRate, setGSTRate] = useState(0); // Default value
  const [cartid2, setcartid2] = useState(0); // Default value
  const [removeId, setRemoveId] = useState("");

  const fetchGST = async () => {
    try {
      const GSTData = await gstdata_get_api();
      setGSTRate(parseFloat(GSTData.Data.Rate));
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };

  // Function to handle unavailable images
  const getImageUrl = (item) => {
    return (
      process.env.REACT_APP_API_URL + item.ProductImage ||
      process.env.REACT_APP_API_URL + "default.jpg"
    );
  };

  // Calculate subtotal
  const calculateSubtotal = (item) => {
    const qty = item.Carts[0].QTY;
    const basePrice = parseFloat(item.Carts[0].BasePrice);
    const additionalCost = parseFloat(item.Carts[0].AdditionalCost);
    const subtotal = qty * basePrice;
    return subtotal.toFixed(2);
  };

  const calculateGST = (subtotal) => {
    const gstTotal = subtotal * (gstRate / 100);
    return gstTotal.toFixed(2);
  };

  const [descriptionInput, setDescriptionInput] = useState({});

  const [selectedFiles, setSelectedFiles] = useState({});

  const toggleDescriptionEdit = (cartId) => {
    // setSelectedFiles({});
    setDescriptionInput((prevInput) => ({
      ...prevInput,
      [cartId]: {
        enabled: !prevInput[cartId]?.enabled,
        value: prevInput[cartId]?.value || "",
      },
    }));
  };

  const handleDescriptionChange = (cartId, value) => {
    setDescriptionInput((prevInput) => ({
      ...prevInput,
      [cartId]: {
        ...prevInput[cartId],
        value: value,
      },
    }));
  };

  const handleDescriptionUpload = async (cartId, descriptionValue) => {
    const requestParams = {
      CartID: cartId,
      Brief: descriptionValue,
    };
    try {
      const res = await cart_description_api(requestParams);
      // Handle success or show error messages
      handleExceptionSuccessMessages(res);
      // Clear the description input after successful upload
      handleDescriptionChange(cartId, "");
    } catch (error) {
      console.error("Error uploading description:", error);
      handleExceptionError("Error uploading description.");
    }
  };
  //   useEffect(() => {
  //     const storedFilesString = localStorage.getItem("selectedFiles");
  //     const storedFilesObject = storedFilesString
  //       ? JSON.parse(storedFilesString)
  //       : {};
  //     setSelectedFiles(storedFilesObject);
  //   }, []);

  //   // Update selectedFiles in localStorage when it changes
  //   useEffect(() => {
  //     const filesString = JSON.stringify(selectedFiles);
  //     localStorage.setItem("selectedFiles", filesString);
  //   }, [selectedFiles]);

  const handleFileSelection = async (cartId, files) => {
    // const oldFile = cartAttachment[cartId] ? selectedFiles[cartId] : [];
    const newFile = Array.from(files);
    const allFile = [ ...newFile];
    const finaldata = [
      {
        [cartid2 !== 0 ? cartid2 : cartId]: allFile,
      },
    ];
    console.log(finaldata);
    // alert(1);
    console.log(cartid2);
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [cartid2 !== 0 ? cartid2 : cartId]: allFile,
    }));
    console.log("selected files", selectedFiles);
    localStorage.setItem("selectedFiles", selectedFiles);
    console.log("selected files", selectedFiles);

    setcartid2(0);
    const file = [...finaldata[0][cartid2]];
    if (!files) {
      console.error("No files selected for artwork upload");
      handleExceptionError("No files selected for artwork upload");
      return;
    }
    const fileObject = file?.reduce((obj, file, index) => {
      obj[index] = file;
      return obj;
    }, {});

    // files?.map((file) => {return { Attachment: file } })

    try {
      const requestParams = {
        CartID: cartid2 !== 0 ? cartid2 : cartId,
        CartAttachments: fileObject,
      };

      const response = await cart_artwork_api(requestParams);
      console.log("res" , response)
      setapi_data((prevApiData) => {
        return prevApiData.map((item) => {
          if (item.Carts[0].CartID === cartId) {
            // Update the CartAttachment array with the new data
            return {
              ...item,
              Carts: [
                {
                  ...item.Carts[0],
                  CartAttachment: response.cartAttachment, // Assuming the API response contains updated CartAttachment data
                },
              ],
            };
          }
          return item;
        });
      });
      console.log("apidata " , api_data)
      fetchData()

    
      // You can handle success and error cases here
    } catch (error) {
      console.error("Error uploading artwork:", error);
    }
  };

  // useEffect(() => {
  //     // Convert the selectedFiles object to a JSON string
  //     const filesString = JSON.stringify(selectedFiles);

  //     // Store the JSON string in local storage
  //     localStorage.setItem("selectedFiles", filesString);
  //   }, [selectedFiles]);

  // const convertFilesToBase64 = async (files) => {
  //   const base64Promises = Array.from(files).map((file) => {
  //       return new Promise((resolve, reject) => {
  //           const reader = new FileReader();
  //           reader.readAsDataURL(file);
  //           reader.onload = () => resolve(reader.result);
  //           reader.onerror = (error) => reject(error);
  //       });
  //   });
  //   return Promise.all(base64Promises);
  // };

  const handleArtworkUpload = async (cartId) => {
    fileInputRef.current.click();
    console.log(selectedFiles, cartId);
    setcartid2(cartId);
    if (selectedFiles) {
    }
  };

  const imageDelete = async (cartID, i, isCartAttachment , CartAttachmentID) => {
    console.log("cartId" ,cartID )
    console.log("CartAttachmentID" ,CartAttachmentID )
    const Response = await delete_artwork_api(cartID , CartAttachmentID)
    console.log("selected" , selectedFiles)
    setapi_data((prevApiData) => {
      return prevApiData.map((item) => {
        if (item.Carts[0].CartID === cartID) {
          // Filter out the deleted CartAttachment from the array
          const updatedCartAttachment = item.Carts[0].CartAttachment.filter(
            (attachment) => attachment.CartAttachmentID !== CartAttachmentID
          );

          // Update the CartAttachment array with the new data
          return {
            ...item,
            Carts: [
              {
                ...item.Carts[0],
                CartAttachment: updatedCartAttachment,
              },
            ],
          };
        }
        return item;
      });
    });
    




  };

  return (
    <div>
      {showConfirmBox && (
        <Alert
          message="Are you sure you want to remove this item from the cart?"
          showConfirmBox={showConfirmBox}
          setShowConfirmBox={setShowConfirmBox}
          onConfirm={handleDelete}
          id={removeId}
        />
      )}

      <ErrorSnackbar
        errorMessages={ExceptionError}
        onClearErrors={clearErrors}
      />
      <SuccessSnackbar
        successMessages={successMessages}
        onclearSuccess={clearSuccess}
      />
      <Head_CateHead />
      <div className="container">
        <Slide_title title="Shopping Cart" className="category_title cart" />
        <hr />
        <div className="cart_wrap">
          { (
            <div className="cart_list">
              {api_data?.map((item) => (
                <div
                  className="cart_item"
                  key={item.ProductID}
                  style={{ marginRight: "1vw" }}
                >
                  <div className="cart_img">
                    {/* Use item.ProductImage here */}
                    <img src={getImageUrl(item)} alt={item.ProductName} />
                  </div>
                  <div className="cart_info">
                    <div className="cart_info1">
                      <div className="cart_detail">
                        <div className="cart_title">
                          <h3>{item.ProductName}</h3>
                          {item.Carts[0].randomnumber != "" &&
                            item.Carts[0].randomnumber != null && (
                              <p style={{ width: "300px" }}>
                                {item?.Carts[0]?.randomnumber?.map(
                                  (randomnumber, i) => {
                                    return (
                                      <span key={i}>
                                        {randomnumber.name}({randomnumber.value}
                                        ){" "}
                                      </span>
                                    );
                                  }
                                )}
                              </p>
                            )}
                          {/* Use ProductDescription here */}
                          {/* <div dangerouslySetInnerHTML={{ __html: item.ProductDescription }} /> */}
                        </div>
                        <div className="cart_count ">
                          {/* <button className="">-</button> */}
                          <h5>
                            QTY: {item.Carts[0].QTY} X $
                            {item.Carts[0].BasePrice}
                          </h5>
                          {/* <button className="">+</button> */}
                        </div>
                        <div className="cart_price">
                          <h5>
                            $
                            {(
                              item.Carts[0].BasePrice * item.Carts[0].QTY
                            ).toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="cart_info2">
                      <div className="left_side_button">
                        <button
                          className={
                            "cart_info2_bt1" +
                            " " 
                           
                          }
                          onClick={() =>
                            handleArtworkUpload(item.Carts[0].CartID)
                          }
                        >
                          <img src={upload} />
                          Artwork
                        </button>
                        <input
                          ref={fileInputRef}
                          className="mt-2"
                          type="file"
                          multiple
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={(e) =>
                            handleFileSelection(
                              item?.Carts[0]?.CartID,
                              e.target.files
                            )
                          }
                        />

                        <button
                          className="cart_info2_bt2"
                          onClick={() =>
                            toggleDescriptionEdit(item.Carts[0].CartID)
                          }
                        >
                          <img src={disc} alt="disc" />
                          Description
                        </button>
                      </div>
                      <div className="right_side_button">
                        <button
                          className="cart_info2_bt3"
                          onClick={() => removeCartItem(item.Carts[0].CartID)}
                        >
                          <img src={remove} alt="remove" />
                          Remove
                        </button>
                      </div>
                    </div>
                    <div
                      className="allImage"
                      style={{ marginTop: "1vw", marginBottom: "1vw" }}
                    >
                      { 
                      item?.Carts[0]?.CartAttachment?.map((img, i) => (
                            <>
                              <div className="artwork_image">
                                <img
                                  className="h-w-100"
                                  src={
                                    "https://api.bebranded.com.au/" +
                                    img.Attachment
                                  }
                                  alt=""
                                />
                                <div
                                  className="delete_selected_mage"
                                  onClick={() =>
                                    imageDelete(item.Carts[0].CartID, i, true,img.CartAttachmentID)
                                  }
                                >
                                  x
                                </div>
                              </div>
                            </>
                          ))}
                    </div>
                    {descriptionInput[item.Carts[0].CartID]?.enabled && (
                      <div className="description_editor mt-2">
                        <textarea
                          value={
                            descriptionInput[item.Carts[0].CartID]?.value != ""
                              ? descriptionInput[item.Carts[0].CartID]?.value
                              : item.Carts[0].Brief
                          }
                          onChange={(e) =>
                            handleDescriptionChange(
                              item.Carts[0].CartID,
                              e.target.value
                            )
                          }
                        />
                        <br></br>
                        <button
                          className="cart_info2_bt3"
                          onClick={() =>
                            handleDescriptionUpload(
                              item.Carts[0].CartID,
                              descriptionInput[item.Carts[0].CartID]?.value ||
                                ""
                            )
                          }
                        >
                          Upload Description
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="cart_total">
            <div className="cart_title">
              <h3>Order Summary</h3>
            </div>
            <div className="cart_total_list">
              <div className="cart_count1">
                <h3>Subtotal</h3>
                <h4>
                  $
                  {api_data
                    ?.reduce(
                      (total, item) =>
                        total + parseFloat(calculateSubtotal(item)),
                      0
                    )
                    .toFixed(2)}
                </h4>
              </div>
              <hr className="cart_total_hr" />
              <div className="cart_count1">
                <h3>GST ({gstRate.toFixed(2)}%)</h3>
                <h4>
                  $
                  {calculateGST(
                    api_data?.reduce(
                      (total, item) =>
                        total + parseFloat(calculateSubtotal(item)),
                      0
                    )
                  )}
                </h4>
              </div>
              <hr className="cart_total_hr" />
              <div className="cart_count1">
                <h3>Order total</h3>
                <h4>
                  $
                  {(
                    parseFloat(
                      api_data?.reduce(
                        (total, item) =>
                          total + parseFloat(calculateSubtotal(item)),
                        0
                      )
                    ) +
                    parseFloat(
                      calculateGST(
                        api_data?.reduce(
                          (total, item) =>
                            total + parseFloat(calculateSubtotal(item)),
                          0
                        )
                      )
                    )
                  ).toFixed(2)}
                </h4>
              </div>
            </div>
            {api_data.length === 0 ? (
              <div>
                <p>Please add items to your cart.</p>
                <Link to="/">
                  <button> Go to Home</button>
                </Link>
              </div>
            ) : (
              // Render the checkout button if api_data is not empty
              <Link to="/checkout">
                <button>Checkout</button>
              </Link>
            )}
            <p>
              Learn more <a href="#">Taxes</a> and <a href="#">Shipping</a>{" "}
              information
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
