import React, { useEffect } from "react";
import { Home } from "./pages/Homepage.js";
import { CategoryListing } from "./pages/CategoryListing.js";
import { Category } from "./pages/Category.js";
import { Products } from "./pages/Products.js";
import { Product_detail } from "./pages/product_detail.js";
import { Cart } from "./pages/cart.js";
import { Search } from "./pages/search.js";
import { Checkout } from "./pages/checkout.js";
import { Account } from "./pages/account.js";
import { Signup } from "./pages/signup.js";
import { Signin } from "./pages/signin.js";
import { Forgot_pass } from "./pages/forgot_pass.js";
import { Confirm_pass } from "./pages/Confirm_pass.js";
import { Acc_change_pass } from "./pages/acc_change_pass.js";
import { Acc_order_list } from "./pages/acc_order_list.js";
import { Acc_wish_list } from "./pages/acc_wish_list.js";
import { Portal_list } from "./pages/portal_list.js";
import { Acc_address } from "./pages/acc_address.js";
import { Acc_order_detail } from "./pages/acc_order_detail.js";
import { Acc_Saddress } from "./pages/acc_Saddress_add.js";
import { Acc_Baddress } from "./pages/acc_Baddress_add.js";
import NotFoundPage from "./pages/404.js";
import OrderThanks from "./pages/order_thanks.js";
import {Faq} from "./pages/faq.js";
import {Terms_condition} from "./pages/terms_condition.js";
import {Contact} from "./pages/contact.js";

// import Pdf from "./assets/img/artwork.pdf";
import {
    BrowserRouter as Router,
    Route,
    Routes,
   
    
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Giveaway } from "./pages/giveaway.js";
import Maintenance_Opps from "./pages/Maintenance_Opps.js";
import PDFviewer from "./components/PDFviewer.js";
import CartModel from "./components/CartModel.js";
import { jwtDecode } from "jwt-decode";
import ProtectedRoute from "./AuthUtils/PrivateRoute.js";
export const Bebranded = () => {
  
    // Redirect to the specified URL
    useEffect(() => {
      // Redirect to the specified URL
      //window.location.href = 'https://www.bebranded.com.au/';
    }, []); // Empty dependency array ensures useEffect runs only once
  };
export const Routes1 = () => {
    
    const isAuthenticated = async (event) => {
        if (!localStorage.getItem('token')) {
            // navigate("/signin"); 
            // navigate("/signin");   
        }
    }
    return (
        <Router>
            <Routes>
                 <Route path="/" element={<Home /> }/>

                {/* Login condition  */}
                <Route path="/search/:slug" element={<Search />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/account" element={<Account />} />
                <Route path="/change_password" element={<Acc_change_pass />} />
                <Route path="/saved_address" element={<Acc_address />} />
                <Route path="/order_detail/:id" element={<Acc_order_detail />} />
                <Route path="/billing_address/:id?" element={<Acc_Baddress />} />
                <Route path="/shipping_address/:id?" element={<Acc_Saddress />} />
                <Route path="/order_list" element={<Acc_order_list />} />
                <Route path="/wishlist" element={<Acc_wish_list />} />
                {/* <Route exact path="/acc_portal" element={<Acc_portal />} /> */}
                {/* Public URL */}
                <Route path="/signup" element={<Signup />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/login" element={<Signin />} />
                <Route path="/forgot_pass" element={<Forgot_pass />} />
                <Route path="/confirm_pass/:urltoken" element={<Confirm_pass />} />
                <Route path="/shop" element={<Category />} />
                <Route path="/category/:slug" element={<CategoryListing />} />
                <Route path="/product/:slug" element={<Product_detail />} />
                <Route path="/:slug" element={<Portal_list />} />
                <Route path="/:categoryslug/:subcategoryslug" element={<Products />} />
                <Route path="/giveaway" element={<Giveaway />} />
                <Route path="/pdfview/:pdfurl" element={<PDFviewer />} />
                <Route path="/" element={<Bebranded />} />
                {/* <Route path="/maintence_opps" element={<Maintenance_Opps />} /> */}
                {/* <Route path="/Portal_Detail" element={<Portal_Detail />} /> */}
                <Route path="/thanks_order" element={<OrderThanks />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/terms_condition" element={<Terms_condition />} />
                <Route path="/Contact" element={<Contact />} />
                {/* <Route path="/:categoryslug/:subcategoryslug" element={<Contact />} /> */}
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/modal" element={<CartModel />} />
                <Route path="/404" element={<NotFoundPage />} />
            </Routes>
        </Router>
    );
};
