// ErrorSnackbar.js
import React, { useState } from 'react';
import Validation from './validation.js';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { AuthApi } from "../service/api.js";
import CloseIcon from '@mui/icons-material/Close';

function LoginPopup({ setLogin }) {
    const [values, setValues] = useState(
        {
            Email: '',
            Password: '',
        }
    )
    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    function handleExceptionError(res) {
        // alert("sf");
        setExceptionError(ExceptionError => [
            ...ExceptionError,
            { id: Date.now(), message: res },
        ]);
    }

    function handleExceptionSuccessMessages(res) {
        setSuccessMessages(successMessages => [
            ...successMessages,
            { id: Date.now(), message: res.data.Message },
        ]);
    }
    function handleInput(event) {
        const newObj = { ...values, [event.target.name]: event.target.value }
        setValues(newObj)
    }
    const [errors, setErrors] = useState({})

    function handleValidation(event) {
        event.preventDefault();
        const validationErrors = Validation(values); // Pass the values object directly
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            AuthApi_api_call(values);
        }
    }
    const AuthApi_api_call = async (values) => {
        // if (errors.length == 0) {
            const res = await AuthApi(values);

            if (res.status == 200) {
                var data = res.data;
                console.log(data);
                localStorage.setItem("UserID", data.data.UserID);
                localStorage.setItem("RoleID", data.data.RoleID);
                localStorage.setItem("FullName", data.data.FullName);
                localStorage.setItem("MobileNumber", data.data.MobileNumber);
                localStorage.setItem("Email", data.data.Email);
                localStorage.setItem("Email", data.data.Email);
                localStorage.setItem("Password", data.data.Password);
                localStorage.setItem("ProfilePic", data.data.ProfilePic);
                localStorage.setItem("LoginCount", data.data.LoginCount);
                localStorage.setItem("UserAccess", data.data.UserAccess);
                localStorage.setItem("PortalEmail", data.data.PortalEmail);
                localStorage.setItem("IsChangePassword", data.data.IsChangePassword);
                localStorage.setItem("IsHolder", data.data.IsHolder);
                localStorage.setItem("IsPortal", data.data.IsPortal);
                localStorage.setItem("IsVisible", data.data.IsVisible);
                localStorage.setItem("CreatedBy", data.data.CreatedBy);
                localStorage.setItem("CreatedOn", data.data.CreatedOn);
                localStorage.setItem("LastModifiedBy", data.data.LastModifiedBy);
                localStorage.setItem("LastModifiedOn", data.data.LastModifiedOn);
                localStorage.setItem("DOB", data.data.DOB);
                localStorage.setItem("Gender", data.data.Gender);
                localStorage.setItem("Bio", data.data.Bio);
                localStorage.setItem("token", data.token);
                handleExceptionSuccessMessages(res);
                setTimeout(() => {
                    window.location.reload(); // Reload the current page
                }, 10);
            } else {
                handleExceptionError(res.response.data.ErrorMessage)
            }
            return (data);
        // }
    };
    function clearErrors(id) {
        setExceptionError(prevMessages =>
            prevMessages.filter(msg => msg.id !== id)
        );
    }
    function clearSuccess(id) {
        setSuccessMessages(prevMessages =>
            prevMessages.filter(msg => msg.id !== id)
        );
    }
    const [isOpen, setIsOpen] = useState(true); 
    const handleClose = () => {
        setIsOpen(false);
        setLogin(false);
    };
    return isOpen &&(<dialog open>
        <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
        <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
        <div class="container sign_container">
            <div>
                <div class="slide-title de_pop">
                    <h2 class="signin_page_title" style={{ marginTop: '0' }}>Sign in</h2>
                    <CloseIcon onClick={handleClose} />
                </div>
            </div>
            <div class="sign_in_wrapper">
                <form onSubmit={handleValidation}>
                    <div className="checkout_form_tel">
                        <h3>Email</h3>
                        <input type="text" style={{ width: '100%' }} name="Email" id="Email" onChange={handleInput} />
                        {errors.Email && <p className='giv_error'>{errors.Email}</p>}
                    </div>
                    <div className="checkout_form_tel">
                        <h3>Password</h3>
                        <input type="Password" style={{ width: '100%' }} name="Password" id="Password" onChange={handleInput} />
                        {errors.Password && <p className='giv_error'>{errors.Password}</p>}
                        <div className='space_between'>
                            <a href="/forgot_pass" className='forgot_password'>Forgot Password</a>
                            <span className='forgot_password'>Don't have an account?<a href="/signup" className='signup_cont'> Sign Up</a></span>
                        </div>

                    </div>
                    <button class="frm_button form_submit_form" type="submit">Sign in</button>
                    {/* <button class="frm_button form_submit_form close_pop" onClick={() => {
                        const dialog = document.querySelector('dialog');
                        dialog.close();
                    }}>Close</button> */}
                </form>
            </div>
        </div>
    </dialog>);
}
export default LoginPopup;
