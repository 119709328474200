import React, { useState, useEffect } from 'react';
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import { Category_Header } from "./../components/category-header.js";
import Validation from '../components/validation.js';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import { useNavigate } from 'react-router-dom';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { confirm_password, verify_link } from "../service/api.js";
import { useParams } from 'react-router-dom';
import Head_CateHead from '../components/Head_CateHead.js';

export const Confirm_pass = () => {
 
  const navigate = useNavigate();
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);

  const { urltoken } = useParams(); 
  // console.log('useParams:', urltoken);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [apiMessage, setApiMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');



  useEffect(() => {
    const isValid = validateToken(urltoken);
    setIsTokenValid(isValid);

    if (!isValid) {
      setErrorMessage('URL expired.'); // Set error message for invalid token
    }
  }, [urltoken]);

  const validateToken = async (urltoken) => {
    try {
      const response = await verify_link(urltoken);
      console.log('verify_link:', response.data.Message);
      return response.data.isValid; // Assuming the API response contains a boolean indicating token validity.
    } catch (error) {
      console.error('API error:', error);
      handleExceptionError("An error occurred while updating the password.");
      return false; // Return false in case of an error.
    }
  };


  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError(ExceptionError => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  function handleExceptionSuccessMessages(res) {
    setSuccessMessages(successMessages => [
      ...successMessages,
      { id: Date.now(), message: res.data.Message },
    ]);
  }

  function clearErrors(id) {
    setExceptionError(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    ); 
  }


  
  const [values, setValues] = useState(
    {
      Password: '',
      new_password:'',
    }
  )

  function handleInput(event) {
    const newObj = { ...values, [event.target.name]: event.target.value }
    setValues(newObj)
  }



  const AuthConfirm_api_call = async (values) => {
    if (isTokenValid) {
      try {
        const res = await confirm_password(urltoken, values); // Pass token and password to the API function
        if (res.status === 200) {
          var data = res.data;
          console.log(data);
       
          handleExceptionSuccessMessages(res);
          setTimeout(() => {
              navigate("/signin");
            }, 2500);
        } else {
          handleExceptionError(res.response.data.ErrorMessage);
        }
      } catch (error) {
        console.error('API error:', error);
        handleExceptionError("An error occurred while updating the password.");
      }
    }
  };


  const [errors, setErrors] = useState({})

  function handleValidation(event) {
    event.preventDefault();
    const validationErrors = Validation(values); // Pass the values object directly
    // Add password confirmation validation
  if (values.Password !== values.new_password) {
    validationErrors.confirmPassword = "Passwords do not match";
  }
    setErrors(validationErrors);
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isTokenValid) {
      AuthConfirm_api_call(values);
    }
  }, [errors]);





  return (
    <div>
      <ErrorSnackbar
        errorMessages={ExceptionError}
        onClearErrors={clearErrors}
      />
      <SuccessSnackbar
        successMessages={successMessages}
        onclearSuccess={clearSuccess}
      />
      <Head_CateHead />
      <div class="container sign_container">
        <Slide_title title="Confirm Password" className="signin_page_title" />
        <div class="sign_in_wrapper">
          <form onSubmit={handleValidation}>
            <div className="checkout_form_tel">
              <h3> New Password</h3>
              <input
                type="Password"
                name="Password"
                id="Password"
                onChange={handleInput}
              />
              {errors.Password && (
                <p className="giv_error">{errors.Password}</p>
              )}
            </div>
            <div className="checkout_form_tel">
              <h3> Confirm New Password</h3>
              <input
                type="Password"
                name="new_password"
                id="new_password"
                onChange={handleInput}
              />
              {errors.new_password && (
                <p className="giv_error">{errors.new_password}</p>
              )}
              {errors.confirmPassword && (
                <p className="giv_error">{errors.confirmPassword}</p>
              )}
            </div>
            <button className="frm_button form_submit_form" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}